import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRefreshMutation } from '../../app/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../app/store';
import { Role } from '../../types';
import { BasketballLoader } from 'components/BasketballLoader/BasketballLoader';

//Current user should be changed to obj when actual users will need to be made
//Sign up, forgot password, etc.. can be added here when its needed.
interface AuthContextInterface {
  logout: Function;
  error: FetchBaseQueryError | SerializedError | undefined;
  loading: boolean;
}

const defaultAuthContext = {
  logout: () => {},
  error: undefined,
  loading: false,
};

const AuthContext =
  React.createContext<AuthContextInterface>(defaultAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const userStore = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [refresh, { isUninitialized, isLoading, data, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (!userStore.token && isUninitialized) {
      refresh();
    }
  }, [isUninitialized, userStore.token, refresh]);

  function logout() {
    dispatch({ type: 'logout' });
  }

  return (
    <AuthContext.Provider
      value={{
        logout,
        error,
        loading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function RequireAuth() {
  const { error } = useAuth();
  const location = useLocation();
  const userStore = useSelector((state: RootState) => state.user);

  if (!userStore.token && !error) {
    return <BasketballLoader />;
  }

  return userStore.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export const RequireAccess: React.FC<{ requires: Role }> = ({ requires }) => {
  const { error } = useAuth();
  const location = useLocation();
  const userStore = useAppSelector((store) => store.user);

  if (!userStore.token && !error) {
    return <BasketballLoader />;
  }

  return userStore.isAuthenticated &&
    canPerformAction(userStore.user?.role || 'USER', requires) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export const canPerformAction = (role: Role = 'USER', requiresRole: Role) => {
  const map: { [key: Role]: number } = {
    SUPER_ADMIN: 1,
    LEAGUE_ADMIN: 2,
    EVENT_ADMIN: 3,
    SCORE_KEEPER: 4,
    REFEREE: 5,
    USER: 6,
  };
  return map[role] <= map[requiresRole];
};
