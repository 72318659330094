import {
  TriangleUpIcon,
  TriangleDownIcon,
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons';
import {
  HStack,
  Heading,
  Collapse,
  VStack,
  Text,
  Input,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  FormControl,
  IconButton,
  FormLabel,
  Button,
} from '@chakra-ui/react';
import { TimelineContextProvider } from 'components/GameTimeline/TimelineContext';
import { setHours } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  useGenerateScheduleMutation,
  useGetLeaguesForEventQuery,
  useImportScheduleMutation,
  useSaveScheduleMutation,
  useUpdateLeagueEventMutation,
} from '../../../app/api';
import Card from '../../../components/Card/Card';
import { GameTimeline } from '../../../components/GameTimeline/GameTimeline';
import { TimeInput } from '../../../components/TimeInput';
import { useEventContext } from '../../../contexts/EventContext';

interface ScheduleCreationSectionProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  eventId: number;
}

export const ScheduleCreationSection: React.FC<
  ScheduleCreationSectionProps
> = ({ isOpen, onClose, onOpen, eventId }) => {
  const { currentEvent } = useEventContext();
  const { data: leagues, refetch: refetchLeagues } =
    useGetLeaguesForEventQuery(eventId);
  const [timeslots, setTimeslots] = useState<[Date, Date][]>([]);
  const [generateCount, setGenerateCount] = useState(0);
  const [selectedDates, setSelectedDates] = useState<[Date, Date]>(
    currentEvent
      ? [new Date(currentEvent.startDate), new Date(currentEvent.endDate)]
      : [new Date(), new Date()]
  );
  const [gameDuration, setGameDuration] = useState<number>(20);
  const [timeBetweenGames, setTimeBetweenGames] = useState<number>(5);
  const [updateLeagueEvent] = useUpdateLeagueEventMutation();
  const [generateSchedule, { data: generatedGames }] =
    useGenerateScheduleMutation();
  const [saveSchedule] = useImportScheduleMutation();

  const onTimeslotChange = (
    newTime: Date,
    index: number,
    startOrEnd: 'start' | 'end'
  ) => {
    const [start, end] = timeslots[index];
    let time = startOrEnd === 'start' ? start : end;
    if (isNaN(time.getTime())) {
      time = new Date(currentEvent?.startDate || Date.now());
    }
    setTimeslots([
      ...timeslots.slice(0, index),
      startOrEnd === 'start' ? [newTime, end] : [start, newTime],
      ...timeslots.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (currentEvent && isOpen) {
      if (currentEvent) {
        generateSchedule({
          eventId: currentEvent.id,
          timeslots,
          gameDuration,
          timeBetweenGames,
        });
      }
    }
  }, [
    isOpen,
    currentEvent,
    gameDuration,
    timeBetweenGames,
    generateSchedule,
    generateCount,
  ]);

  return (
    <Card>
      <HStack w="100%">
        <Heading as="h3" size="md">
          Schedule Creation
        </Heading>
        {/* <Button>Batch Create</Button> */}
        {isOpen ? (
          <TriangleUpIcon onClick={onClose} />
        ) : (
          <TriangleDownIcon onClick={onOpen} />
        )}
      </HStack>
      <Collapse in={isOpen}>
        <VStack>
          {/* <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(dates) => setSelectedDates([dates[0], dates[1]])}
          /> */}
          <HStack>
            <Heading as="h4" size="md">
              Add Timeslots
            </Heading>
            <IconButton
              size="sm"
              icon={<AddIcon />}
              aria-label="add-timeslot"
              onClick={() =>
                setTimeslots([
                  ...timeslots,
                  [
                    setHours(
                      new Date(currentEvent?.startDate ?? Date.now()),
                      8
                    ),
                    setHours(
                      new Date(currentEvent?.startDate ?? Date.now()),
                      17
                    ),
                  ],
                ])
              }
            />
          </HStack>
          <VStack>
            {timeslots.map(([start, end], index) => (
              <HStack>
                <FormControl>
                  <TimeInput
                    placeholder="From"
                    type="time"
                    date={start}
                    onTimeChange={(newTime) =>
                      onTimeslotChange(newTime, index, 'start')
                    }
                  />
                </FormControl>
                <FormControl>
                  <TimeInput
                    placeholder="To"
                    type="time"
                    date={end}
                    onTimeChange={(newTime) =>
                      onTimeslotChange(newTime, index, 'end')
                    }
                  />
                </FormControl>
                <IconButton
                  size="sm"
                  icon={<MinusIcon />}
                  aria-label="remove-timeslot"
                  onClick={() =>
                    setTimeslots([
                      ...timeslots.slice(0, index),
                      ...timeslots.slice(index + 1),
                    ])
                  }
                />
              </HStack>
            ))}
          </VStack>
          <Heading size="sm">Number of games per league</Heading>
          {leagues?.map((league) => (
            <HStack key={league.id}>
              <Text>{league.name}</Text>
              <NumberInput
                value={league.numGames}
                onChange={(val) => {
                  if (currentEvent) {
                    updateLeagueEvent({
                      eventId: currentEvent.id,
                      leagueId: league.id,
                      leagueEvent: { ...league, numGames: parseInt(val) },
                    }).then(() => refetchLeagues());
                  }
                }}
                size="sm"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </HStack>
          ))}
          <FormControl>
            <FormLabel>Game time (minutes)</FormLabel>
            <NumberInput
              value={gameDuration}
              onChange={(val) => setGameDuration(parseInt(val))}
              size="sm"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel>Time between Games (minutes)</FormLabel>
            <NumberInput
              value={timeBetweenGames}
              onChange={(val) => setTimeBetweenGames(parseInt(val))}
              size="sm"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <GameTimeline
            schedule={generatedGames || { days: [], courts: [] }}
            onGameSelect={() => {}}
          />
          <HStack>
            <Button onClick={() => setGenerateCount(generateCount + 1)}>
              Generate Plan
            </Button>
            <Button
              isDisabled={!generatedGames || !currentEvent}
              onClick={() => {
                if (!!generatedGames && currentEvent) {
                  saveSchedule({
                    schedule: generatedGames,
                    eventId: currentEvent.id,
                  }).then(() => onClose());
                }
              }}
            >
              Save Schedule
            </Button>
          </HStack>
        </VStack>
      </Collapse>
    </Card>
  );
};
