import { GameView, Schedule } from '../../types';
import { TimelineContextProvider } from './TimelineContext';
import { EditableGameEPG } from './EditableGameEPG';

interface GameTimelineProps {
  schedule: Schedule;
  editingSchedule?: Schedule;
  onScheduleChange?: (schedule: Schedule) => void;
  onGameSelect?: (game?: GameView) => void;
  onValidChange?: (valid: boolean) => void;
  editing?: boolean;
}

export const GameTimeline: React.FC<GameTimelineProps> = ({
  schedule,
  editingSchedule,
  onScheduleChange,
  onGameSelect,
  onValidChange,
  editing,
}) => {

  // JSX
  return (
    <TimelineContextProvider schedule={schedule} editingSchedule={editingSchedule} onEditingScheduleChange={onScheduleChange} onGameSelect={onGameSelect} onScheduleValidChange={onValidChange ? onValidChange : () => {}}>
      <EditableGameEPG editing={!!editing} />
    </TimelineContextProvider>
  );
};
