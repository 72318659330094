import { Heading, Box, Link, useDisclosure } from '@chakra-ui/react';
import {
  useGetPlayersForEventQuery,
  useGetTeamsForEventQuery,
} from '../../app/api';
import Page from '../../components/Page/Page';
import { Link as RouterLink } from 'react-router-dom';
import { TableControls } from '../../components/TableControls/TableControls';
import { YCTable } from '../../components/YCTable/YCTable';
import { useMemo } from 'react';
import CreatePlayerModal from './CreatePlayerModal';
import { useEventContext } from '../../contexts/EventContext';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Cell } from 'react-table';
import { Player } from '../../types';
import Card from 'components/Card/Card';

const Players = ({}) => {
  const { currentEvent } = useEventContext();
  const { data } = useGetPlayersForEventQuery(currentEvent?.id ?? skipToken);
  const { data: teamData } = useGetTeamsForEventQuery(
    currentEvent?.id ?? skipToken
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Team',
        Cell: ({ row }: Cell<Player>) =>
          teamData?.find((team) => team.id === row.original.teamId)?.name || '',
      },
      {
        Header: 'Total Pts.',
        accessor: 'totalPoints',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }: Cell<Player>) => (
          <Link color="blue.500" as={RouterLink} to={`${value}`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Page flexDir="row" display="flex">
        <Box padding={5} flexGrow={1} overflow="auto">
          <Card>
            <Heading>Players</Heading>
            <TableControls addAction={onOpen} />
          </Card>
          <Card mt={2}>
            <YCTable columns={columns} data={data || []} />
          </Card>
        </Box>
      </Page>
      <CreatePlayerModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default Players;
