import { HStack, Heading, IconButton, Text, Button, useColorModeValue } from "@chakra-ui/react";
import { useSaveScheduleMutation, useUpdateGameMutation } from "app/api";
import { useAppSelector } from "app/store";
import Card from "components/Card/Card";
import { GameTimeline } from "components/GameTimeline/GameTimeline";
import { canPerformAction } from "contexts/AuthContext/AuthContext";
import { useEventContext } from "contexts/EventContext";
import GameViewModal from "pages/games/GameViewModal";
import React, { useEffect, useState } from "react";
import { GameView, Schedule } from "types";

interface SportEventSchedulePanelProps {
    editing: boolean;
    setEditing: (editing: boolean) => void;
    schedule?: Schedule;
    refetchSchedule: () => void;
}

export const SportEventSchedulePanel: React.FC<SportEventSchedulePanelProps> = ({ editing, setEditing, schedule, refetchSchedule }) => {
    const [selectedGame, setSelectedGame] = useState<GameView | undefined>(undefined);
    const [editingSchedule, setEditingSchedule] = useState<Schedule | undefined>(schedule);
    const [valid, setValid] = useState<boolean | undefined>(undefined);
    const userStore = useAppSelector((store) => store.user);
    const [saveScheduleMutation] = useSaveScheduleMutation();
    const { currentEvent } = useEventContext();

    const handleSaveEditButtonClick = () => {
      if (editing && editingSchedule && currentEvent) {
        saveScheduleMutation({ schedule: editingSchedule, eventId: currentEvent.id })
        .then(refetchSchedule);
      }
      setEditing(!editing);
    }

    useEffect(() => {
      if (schedule) {
        setEditingSchedule(schedule);
      }
    }, [schedule, setEditingSchedule]);

    return (
        <Card overflow="auto">
        <HStack justify="space-between">
          <Heading>Court Schedule {editing && "(Editing)"}</Heading>
          {canPerformAction(userStore.user?.role, 'EVENT_ADMIN') &&
            <Button
              isDisabled={editing && !valid}
              aria-label={editing ? "finish editing" : "edit game schedule"}
              onClick={handleSaveEditButtonClick}
            >
              {editing ? 'Save ' : 'Edit '}
          </Button>
          }
         </HStack>
        {schedule ? (
          <GameTimeline onScheduleChange={setEditingSchedule} editing={editing} schedule={schedule} editingSchedule={editingSchedule} onGameSelect={setSelectedGame} onValidChange={setValid} />
        ) : (
          <Text>No schedule found</Text>
        )}
        {selectedGame && (
          <GameViewModal
            game={selectedGame}
            onClose={() => setSelectedGame(undefined)}
            isOpen={!!selectedGame}
          />
        )}
      </Card>
    )
}