import { useColorModeValue, Collapse } from "@chakra-ui/react";
import { DndContext, DragEndEvent, DragMoveEvent, DragOverlay, DragStartEvent, Modifier, pointerWithin, rectIntersection } from "@dnd-kit/core";
import { addMinutes, endOfDay, isEqual, startOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import { GameView } from "types";
import { GameEPG } from "./GameEPG";
import { useTimelineContext } from "./TimelineContext";
import { UnscheduledGameList } from "./UnscheduledGameList";
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { isOverlapping } from "utils/GameUtils";
import { useThrottle } from "app/utils";
import { GameBox } from "./GameBox";
import { SCHEDULE_DROPPABLE_ID, UNSCHEDULED_LIST_DROPPABLE_ID } from "./GameTimelineConstants";
import differenceInMinutes from "date-fns/differenceInMinutes";
import clamp from "date-fns/clamp";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import closestTo from "date-fns/closestTo";

const COURT_HEIGHT = 80;
const DEFAULT_HOUR_WIDTH = 400;

interface EditableGameEPGProps { 
  editing: boolean;
}

export const EditableGameEPG: React.FC<EditableGameEPGProps> = ({ editing }) => {
  const { isLoading, schedule, selectedDay, editSelectedDay, setIsLoading, unscheduledGames, dayModified, setDayModified, setUnscheduledGames } = useTimelineContext();
  const [lastUnscheduled, setLastUnscheduled] = useState<GameView[] | null>(null);
  //Drag-n-Drop behavior
  const [draggingGame, setDraggingGame] = useState<GameView | undefined>();
  const [draggingFrom, setDraggingFrom] = useState<string | undefined>();
  const [contentScroll, setContentScroll] = useState<{ x: number, y: number } | undefined>(undefined);
  const dragOverlayColor = useColorModeValue('blackAlpha.400', 'whiteAlpha.400');
  const snapToX = DEFAULT_HOUR_WIDTH / 12;
  const snapToY = COURT_HEIGHT;

  const eachTimeslot = useMemo(() => {
    if (selectedDay?.date) {
      const currentDay = new Date(selectedDay.date);
      console.log(startOfDay(currentDay));
      console.log(endOfDay(currentDay));
      return eachMinuteOfInterval({ start: startOfDay(currentDay), end: endOfDay(currentDay) }, { step: 5 });
    } else {
      return [];
    }
  }, [selectedDay?.date]);

  const handleDragStart = (event: DragStartEvent) => {
    const gameId = parseInt(event.active.id.toString());
    const gameInUnscheduled = unscheduledGames.find(g => g.id === gameId);
    if (gameInUnscheduled) {
      setDraggingFrom(UNSCHEDULED_LIST_DROPPABLE_ID);
      setDraggingGame(gameInUnscheduled);
    } else if (selectedDay && selectedDay.games.hasOwnProperty(gameId)) {
      setDraggingFrom(SCHEDULE_DROPPABLE_ID);
      setDraggingGame(selectedDay.games[gameId]);
    }
    // Check unscheduled games first, if not there, get from selectedDay
  };
    

  const handleDragEnd = (event: DragEndEvent) => {
    // Reset all dragging state
    setLastUnscheduled(null);
    setDraggingGame(undefined);
    setDraggingFrom(undefined);
  }

  type GameModificationCalculator = (args: { game: GameView, x: number, y: number }) => GameView;

  const calculateNewGameDetailsFromPositionDelta: GameModificationCalculator = ({ game, x, y })  => {
    const gameDuration = differenceInMinutes(game.endTime, game.startTime);
    const newStartTime = closestTo(addMinutes(game.startTime, Math.floor(x / snapToX) * 5), eachTimeslot.slice(0, eachTimeslot.length - (gameDuration / 5 - 1)))!;
    const moveCourts = Math.floor(y / snapToY);
    if (moveCourts == 0 && isEqual(newStartTime, game.startTime)) {
      return game;
    }
    const moveMinutes = differenceInMinutes(newStartTime, game.startTime);
    return { ...game, startTime: addMinutes(game.startTime, moveMinutes), scheduledAt: addMinutes(game.scheduledAt, moveMinutes), endTime: addMinutes(game.endTime, moveMinutes), courtId: schedule?.courts[schedule?.courts.findIndex(c => c.id === game.courtId) + moveCourts]?.id || game.courtId };
  }

  const calculateNewGameDetailsFromAbsolutePosition: GameModificationCalculator = ({ game, x, y })  => {
    // TODO what happens when games span across multiple days???
    const start = startOfDay(game.startTime);
    const end = endOfDay(game.endTime);
    const gameDuration = differenceInMinutes(game.endTime, game.startTime);
    const newStartTime = clamp(addMinutes(startOfDay(game.startTime), Math.round(x / snapToX) * 5), { start, end: addMinutes(end, -gameDuration) });
    const moveMinutes = differenceInMinutes(newStartTime, game.startTime);
    return { ...game, startTime: newStartTime, endTime: addMinutes(game.endTime, moveMinutes), scheduledAt: addMinutes(game.endTime, moveMinutes), courtId: schedule?.courts[Math.max(Math.round(y / snapToY), 0)].id! }
  }

  const handleDragMove = useThrottle((event: DragMoveEvent) => {
    const activeGame = draggingGame!;

    // Any -> Schedule
    if (event.over?.id === SCHEDULE_DROPPABLE_ID) {
      let moveMinutes: number = 0;
      let moveCourts: number = 0;

      const currentRect = event.active.rect.current.translated!;
      
      // Unscheduled -> Scheduled
      if (draggingFrom === UNSCHEDULED_LIST_DROPPABLE_ID) { 
        const currentRect = event.active.rect.current.translated;
        if (currentRect && event.active.rect.current.initial && contentScroll) {
          // setAdjustPosition({ x: event.active.rect.current.translated.left - event.active.rect.current.initial.left, y: event.active.rect.current.translated.top - event.active.rect.current.initial.top });
          const x = currentRect.left - contentScroll?.x;
          const y = currentRect.top - contentScroll?.y;
        }
      }
      const newGame = draggingFrom === UNSCHEDULED_LIST_DROPPABLE_ID ? calculateNewGameDetailsFromAbsolutePosition({ game: activeGame, x: currentRect.left + contentScroll!.x, y: currentRect.top - event.over.rect.top + contentScroll!.y }) : calculateNewGameDetailsFromPositionDelta({ game: activeGame, x: event.delta.x, y: event.delta.y });
      
      if (newGame !== activeGame) {
        
        if (selectedDay) {
          const newGames = selectedDay.games;
          let newUnscheduled = unscheduledGames.filter(g => g.id !== activeGame.id);
          // Get overlapping before any updates
          const overlapping = Object.values(newGames).filter(g => g.id !== activeGame.id && isOverlapping(g, newGame));
          
          // Handle switching of prreviously unscheduled games
          if (lastUnscheduled?.length) {
            // 1. Get games to add back to games
            const gamesToAddBack = lastUnscheduled.filter(g => !isOverlapping(g, newGame));
            // 2. Remove from updated unscheduled list
            newUnscheduled = newUnscheduled.filter(unscheduled => !gamesToAddBack.map(g => g.id).includes(unscheduled.id));
            // 3. Add to new game list
            gamesToAddBack.forEach(g => newGames[g.id] = g);
          }

          // Handle putting games into unscheduled
          if (overlapping.length) {
            newUnscheduled = newUnscheduled.concat(overlapping);
            setLastUnscheduled(overlapping);
            overlapping.forEach(g => {
              delete newGames[g.id];
            });
          }
          
          delete newGames[activeGame.id];
          setUnscheduledGames(newUnscheduled);
          setDayModified(true);
          editSelectedDay({ ...selectedDay,  games: [...Object.values(newGames), newGame] });
        }
      }
    }

    // Schedule -> Unscheduled
    else if (event.over?.id === UNSCHEDULED_LIST_DROPPABLE_ID && !unscheduledGames.find(g => g.id === draggingGame!.id)) {
      if (selectedDay?.games && draggingGame) {
        const gameToRemove = selectedDay.games[draggingGame.id];
        const newGames = { ...selectedDay.games };
        delete newGames[draggingGame.id];
        setLastUnscheduled(null);
        editSelectedDay({ ...selectedDay, games: Object.values(newGames) });
        setUnscheduledGames([...unscheduledGames, gameToRemove]);
      }
    }
  }, 150);
  
  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} modifiers={[snapCenterToCursor]} onDragMove={handleDragMove}>
      {/* TODO <BasketballLoaderOverlay show={isLoading}></BasketballLoaderOverlay> */}
      <Collapse in={editing}>
        <UnscheduledGameList editing={editing} games={unscheduledGames} hourWidth={DEFAULT_HOUR_WIDTH} />
      </Collapse>
      <GameEPG isEditing={editing} hourWidth={DEFAULT_HOUR_WIDTH} courtHeight={COURT_HEIGHT} onScrollChange={setContentScroll} />
      <DragOverlay>
        {draggingGame &&
          <GameBox
            game={draggingGame}
            hover={false}
            h={COURT_HEIGHT + "px"}
            w={DEFAULT_HOUR_WIDTH / 2 + "px"}
            dropShadow="md"
            borderRadius="xl"
            backgroundColor={dragOverlayColor}
          />}
      </DragOverlay>
    </DndContext>
  )
}