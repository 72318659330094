import { InfoIcon } from "@chakra-ui/icons";
import { HStack, Heading, Button, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Tabs, TabList, Tab, TabPanels, Box, useDisclosure } from "@chakra-ui/react";
import { useGeneratePlayoffScheduleMutation, useGetPlayoffScheduleMutation } from "app/api";
import Card from "components/Card/Card";
import { PlayoffBracket } from "components/PlayoffBracket";
import { GenerateBracketModal } from "pages/events/GenerateBracketModal";
import { useEffect, useState } from "react";
import { LeagueEvent, Round, Team } from "types/dto";

interface SportEventPlayoffBracketPanelProps {
    countGames: (played: boolean) => number;
    leagues?: LeagueEvent[];
    teams?: Team[];
    league: number;
    setSelectedLeague: (league: number) => void
    eventId: number;
    refetchTeams: () => void;
    buttonColor: string;
}

export const SportEventPlayoffBracketPanel: React.FC<SportEventPlayoffBracketPanelProps> = ({ countGames, teams, leagues, league, setSelectedLeague, eventId, refetchTeams, buttonColor}) => {
    const {
        isOpen: isPlayoffInfoOpen,
        onOpen: onPlayoffInfoOpen,
        onClose: onPlayoffInfoClose,
      } = useDisclosure();
      const {
        isOpen: isPlayoffModalOpen,
        onOpen: onPlayoffModalOpen,
        onClose: onPlayoffModalClose,
      } = useDisclosure();
      const [playoffSchedules, setPlayoffSchedules] = useState<{
        [key: number]: Round[];
      }>({});
      const [generatePlayoffs] = useGeneratePlayoffScheduleMutation();
      const [getPlayoffSchedule] = useGetPlayoffScheduleMutation();
      const [showPlayoffSchedule, setShowPlayoffSchedule] = useState<Round[]>([]);

        // Try to fetch the playoff schedule, if fails, open generate playoff modal
  const tryFetchPlayoffSchedule = () => {
      getPlayoffSchedule({
        eventId: eventId,
        leagueId: league,
      })
        .unwrap()
        .then((schedule) => {
          setPlayoffSchedules((prev) => ({
            ...prev,
            [league]: schedule,
          }));
        })
        .catch(() => {
          onPlayoffModalOpen();
        });
  };

    // Effect to change the shown playoff schedule (based on selected league)
    useEffect(() => {
        if (league) {
          setShowPlayoffSchedule(playoffSchedules[league] ?? []);
        }
      }, [league, playoffSchedules]);

      
    return (
        <Card h="100%" maxH="750px" overflow="auto">
          <HStack>
            <Heading>Playoffs</Heading>
            <Button
              isDisabled={countGames(false) !== 0}
              onClick={tryFetchPlayoffSchedule}
            >
              Fetch/Create Bracket
            </Button>
            <IconButton
              variant="ghost"
              icon={<InfoIcon />}
              aria-label={'playoff-info'}
              onClick={onPlayoffInfoOpen}
            />
            <Modal isOpen={isPlayoffInfoOpen} onClose={onPlayoffInfoClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Playoffs Info</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Generation of a bracket is only allowed when all games have
                  been played.
                  <br />
                  Swipe to switch between rounds.
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onPlayoffInfoClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            {leagues && teams && (
              <GenerateBracketModal
                eventId={eventId}
                league={leagues.find((l) => l.id === league)!}
                teams={teams.filter(
                  (team) => team.leagueId === league
                )}
                isOpen={isPlayoffModalOpen}
                onClose={onPlayoffModalClose}
                onSubmit={(numSeeds, persist, date) =>
                  generatePlayoffs({
                    eventId: eventId,
                    leagueId: league,
                    numSeeds,
                    persist,
                    date,
                  })
                    .unwrap()
                    .then((data) =>
                      setPlayoffSchedules({
                        ...playoffSchedules,
                        [league]: data,
                      })
                    )
                    .then(() => refetchTeams())
                    .then(() => onPlayoffModalClose())
                }
              />
            )}
          </HStack>
          <Tabs
            index={
              leagues?.findIndex((l) => l.id === league) ?? 0
            }
            onChange={(idx) => {
              if (leagues) {
                setSelectedLeague(leagues[idx].id);
              }
            }}
            isFitted
            colorScheme={buttonColor}
            overflowY="auto"
          >
            <TabList>
              {leagues?.map((league) => (
                <Tab key={league.id}>{league.name}</Tab>
              ))}
            </TabList>
            <TabPanels>
              <Box overflow="auto">
                {showPlayoffSchedule && (
                  <PlayoffBracket
                    schedule={showPlayoffSchedule}
                    teams={teams || []}
                  />
                )}
              </Box>
            </TabPanels>
          </Tabs>
        </Card>
    );
}