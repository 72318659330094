import { Heading, Box, Link } from '@chakra-ui/react';
import { useGetAllGamesQuery } from '../../app/api';
import Page from '../../components/Page/Page';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TableControls } from '../../components/TableControls/TableControls';
import { YCTable } from '../../components/YCTable/YCTable';
import { useMemo } from 'react';

const Games = ({}) => {
  const { data, error, isLoading } = useGetAllGamesQuery();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: ({ value }: { value: string }) =>
          new Date(value).toLocaleString(),
      },
      {
        Header: 'Home Team',
        accessor: 'homeTeamId',
      },
      {
        Header: 'Away Team',
        accessor: 'awayTeamId',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }: { value: string }) => (
          <Link color="blue.500" as={RouterLink} to={`/event/${value}`}>
            View
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page flexDir="row" display="flex">
      <Box padding={5} flexGrow={1} overflow="auto">
        <Heading>Games</Heading>
        <TableControls editAction={() => navigate('/schedule')} />
        <YCTable columns={columns} data={data || []} />
      </Box>
    </Page>
  );
};

export default Games;
