import * as React from 'react';
import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { NavBar } from '../NavBar/NavBar';
import { Link, useLocation } from 'react-router-dom';

const Page: React.FC<BoxProps> = ({ children, ...props }): JSX.Element => {
  const location = useLocation();
  const paths = location.pathname
    .split('/')
    .filter((path) => ![''].includes(path));
  return (
    <NavBar>
      <Box minH="100vh" {...props}>
        {children}
      </Box>
    </NavBar>
  );
};

export default Page;
