import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Divider,
  GridItem,
  Stack,
  Button,
  useColorModeValue,
  Heading,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useAppSelector } from 'app/store';
import { useNavigate } from 'react-router-dom';
import { useGetGameStatsQuery, useVerifyGameMutation } from '../../app/api';
import { Game, GameView } from '../../types';
import { TeamStats } from './TeamStats';
import { canPerformAction } from '../../contexts/AuthContext/AuthContext';
import { ConfirmModal } from 'components/ConfirmModal';

interface GameViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  game: GameView;
}

const GameViewModal: React.FC<GameViewModalProps> = ({
  isOpen,
  onClose,
  game,
}) => {
  const editButtonColor = useColorModeValue('orange', 'blue');
  const navigate = useNavigate();
  const { data, error } = useGetGameStatsQuery(game.id);
  const [verifyGame, { isLoading: verifyLoading }] = useVerifyGameMutation();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const userStore = useAppSelector((store) => store.user);

  const isAdmin = canPerformAction(userStore.user?.role, 'EVENT_ADMIN');
  const canVerify = isAdmin && game.played && !game.verified;

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {game && (
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Heading>
                {game.homeTeamName} vs. {game.awayTeamName}
              </Heading>
              <Button
                colorScheme={editButtonColor}
                onClick={() => navigate(`../scoringV2/${game.id}`)}
              >
                {game.played ? 'Start' : 'Edit'} Scoring
              </Button>
              {canVerify && (
                <Button colorScheme={editButtonColor} onClick={onConfirmOpen}>
                  Verify
                </Button>
              )}
            </HStack>
            <ConfirmModal
              isLoading={verifyLoading}
              title="Verify Game"
              message="Are you sure you want to verify this game? It cannot be undone."
              confirmText="Verify"
              cancelText="Cancel"
              isOpen={isConfirmOpen}
              onClose={onConfirmClose}
              onConfirm={async () => {
                await verifyGame({ id: game.id });
                onConfirmClose();
                onClose();
              }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              align="stretch"
              w="full"
              direction={{ base: 'column', md: 'row' }}
            >
              <TeamStats
                id={game.homeTeamId}
                name={game.homeTeamName}
                drawId={game.homeTeamDrawId}
                score={game.homeTeamScore}
                won={game.homeTeamScore > game.awayTeamScore}
                players={data?.homeTeam}
                isHome={true}
              />
              <Divider orientation="vertical" />
              <TeamStats
                id={game.awayTeamId}
                name={game.awayTeamName}
                drawId={game.awayTeamDrawId}
                score={game.awayTeamScore}
                won={game.awayTeamScore > game.homeTeamScore}
                players={data?.awayTeam}
                isHome={false}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
};

export default GameViewModal;
