import { CheckCircleIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  Bracket,
  IRoundProps,
  Seed,
  SeedItem,
  SeedTeam,
  SeedTime,
} from 'react-brackets';
import { useEventContext } from '../contexts/EventContext';
import { Team } from '../types';
import { PlayoffSchedule, Round } from '../types/dto';
import { PlayoffGameModal } from './PlayoffGameModal';

interface PlayoffBracketProps {
  schedule: Round[];
  teams: Team[];
}

export const PlayoffBracket: React.FC<PlayoffBracketProps> = ({
  schedule,
  teams,
}) => {
  return (
    <Bracket
      rounds={schedule.map((s) => ({
        ...s,
        seeds: s.games.map((g) => {
          return {
            ...g,
            id: g.id,
            teams: [
              teams.find((t) => t.id === g.homeTeamId)!,
              teams.find((t) => t.id === g.awayTeamId)!,
            ],
          };
        }),
      }))}
      swipeableProps={{ enableMouseEvents: true }}
      renderSeedComponent={({ seed, breakpoint }) => (
        <SeedComponent seed={seed} breakpoint={breakpoint} />
      )}
    />
  );
};

const SeedComponent: React.FC<{ seed: any; breakpoint: number }> = ({
  seed,
  breakpoint,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { currentEvent } = useEventContext();
  const home = seed.teams?.[0];
  const away = seed.teams?.[1];
  return (
    <>
      <Seed mobileBreakpoint={breakpoint}>
        <SeedItem>
          <HStack justify="space-between" px="2" py="1">
            <VStack>
              <Flex w="100%" align="left">
                {home
                  ? `${home.rank ? home.rank + ') ' : ''}${home.name}`
                  : '----------'}
              </Flex>
              <Flex w="100%" align="left">
                {away
                  ? `${away.rank ? away.rank + ') ' : ''}${away.name}`
                  : '----------'}
              </Flex>
            </VStack>
            <IconButton
              isDisabled={seed.played}
              onClick={onOpen}
              variant="ghost"
              icon={seed.played ? <CheckCircleIcon /> : <SettingsIcon />}
              aria-label="playoff-game-settings"
            />
          </HStack>
        </SeedItem>
        <SeedTime mobileBreakpoint={breakpoint}>
          {new Date(seed.startTime).toLocaleTimeString()} | Court:{' '}
          {seed.courtId}
        </SeedTime>
      </Seed>
      {currentEvent && (
        <PlayoffGameModal
          gameId={seed.id}
          isOpen={isOpen}
          onClose={onClose}
          eventId={currentEvent.id}
        />
      )}
    </>
  );
};
