import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import {
  Teams,
  Courts,
  SportEvents,
  Games,
  Leagues,
  GameScheduleView,
  Login,
  Landing,
} from './pages';
import Players from './pages/players/Players';
import {
  AuthProvider,
  RequireAccess,
  RequireAuth,
} from './contexts/AuthContext/AuthContext';
import SportEventPage from './pages/events/SportEventPage/SportEventPage';
import { EventContextProvider, RequireEvent } from './contexts/EventContext';
import TeamViewPage from './pages/teams/TeamViewPage';
import PlayerViewPage from './pages/players/PlayerViewPage';
import { customTheme } from './theme';
import { Scoring } from './pages/games/Scoring';
import { CourtViewPage } from './pages/courts/CourtViewPage';
import NotFound from './pages/NotFound';
import { InitializeSportEventPage } from './pages/events/InitializeSportEventPage/InitializeSportEventPage';
import './App.css';
import { DndContext } from '@dnd-kit/core';
import { FeedPage } from './pages/feed/FeedPage';
import { Notifications } from './components/Notifications';
import { ScoringV2 } from './pages/games/ScoringV2';

export const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={customTheme}>
        <AuthProvider>
          <Router>
            <EventContextProvider>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Landing />} />
                  <Route path="leagues" element={<Leagues />} />
                  <Route path="events/:eventId" element={<RequireEvent />}>
                    <Route path="schedule" element={<GameScheduleView />} />
                    {/* Requires ScoreKeeper */}
                    <Route
                      element={<RequireAccess requires={'SCORE_KEEPER'} />}
                    >
                      <Route path="scoring/:gameId" element={<Scoring />} />
                      <Route path="scoringv2/:gameId" element={<ScoringV2 />} />
                    </Route>
                    {/* Requires Event Admin */}
                    <Route element={<RequireAccess requires={'EVENT_ADMIN'} />}>
                      <Route path="" element={<SportEventPage />} />
                      <Route path="events" element={<SportEvents />} />
                      <Route path="teams" element={<Teams />} />
                      <Route path="courts" element={<Courts />} />
                      <Route path="games" element={<Games />} />
                      <Route path="feed" element={<FeedPage />} />
                      <Route path="players" element={<Players />} />
                      <Route path="leagues" element={<Leagues />} />
                      <Route path="teams/:id" element={<TeamViewPage />} />
                      <Route path="players/:id" element={<PlayerViewPage />} />
                      <Route path="courts/:id" element={<CourtViewPage />} />
                    </Route>
                    {/* Requires League Admin */}
                    <Route
                      element={<RequireAccess requires={'LEAGUE_ADMIN'} />}
                    >
                      <Route
                        path="initialize"
                        element={<InitializeSportEventPage />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Notifications />
            </EventContextProvider>
          </Router>
        </AuthProvider>
      </ChakraProvider>
    </Provider>
  );
};
