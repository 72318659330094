import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Table,
  TableProps,
  Thead,
  Tr,
  Th,
  chakra,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useSortBy,
  Column,
  useFlexLayout,
  PluginHook,
  useFilters,
  useGlobalFilter,
  Row,
  useResizeColumns,
} from 'react-table';

interface YCTableProps extends TableProps {
  columns: Column<any>[];
  data: any[];
  useFlex?: boolean;
  search?: string;
  dataSearchString?: (data: any) => string;
  onRowClick?: (row: any) => void;
}

export const YCTable: React.FC<YCTableProps> = ({
  columns,
  data,
  useFlex,
  search,
  dataSearchString,
  onRowClick,
}) => {
  const filter = useCallback(
    (rows: Row[], columnIds: any[], filterValue: string) => {
      if (!filterValue || filterValue === '') return rows;
      const searchString = filterValue.toLowerCase();
      return rows.filter((item: Row) => {
        const dataString = dataSearchString
          ? dataSearchString(item)
          : JSON.stringify(item.values);
        return dataString.toLowerCase().includes(searchString.toLowerCase());
      });
    },
    []
  );

  const hooks: PluginHook<any>[] = [
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
  ];
  if (useFlex) {
    hooks.push(useFlexLayout);
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data, globalFilter: filter }, ...hooks);

  useEffect(() => {
    if (search) {
      setGlobalFilter(search);
    }
  }, [search, setGlobalFilter]);

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                paddingInline={0}
                // display="flex"
                // justifyContent="center"
              >
                {column.render('Header')}
                {/* <chakra.span pl="4">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )
                  ) : null}
                </chakra.span> */}
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                />
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Tr
              {...row.getRowProps()}
              onClick={(e) => (onRowClick ? onRowClick(row) : undefined)}
            >
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} paddingInline={0}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
