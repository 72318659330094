import {
  Heading,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  Stack,
  HStack,
  useColorModeValue,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useGetLeaguesForEventQuery,
  useGetScheduleForEventQuery,
  useGetTeamsForEventQuery,
} from '../../../app/api';
import Page from '../../../components/Page/Page';
import { useNavigate, useParams } from 'react-router';
import Card from '../../../components/Card/Card';
import { useEventContext } from '../../../contexts/EventContext';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useEffect, useState } from 'react';
import { SettingsIcon } from '@chakra-ui/icons';
import { SportEventSchedulePanel } from './panels/SportEventSchedulePanel';
import { SportEventTeamStatusPanel } from './panels/SportEventTeamStatusPanel';
import { SportEventGamesPlayedPanel } from './panels/SportEventGamesPlayedPanel';
import { SportEventRankingPanel } from './panels/SportEventRankingPanel';
import { SportEventPlayoffBracketPanel } from './panels/SportEventPlayoffBracketPanel';
import { SportEventConfigurationModal } from './SportEventConfigurationModal';

interface SportEventContentProps {
  eventId: number;
}

const SportEventPage: React.FC = () => {
  const { eventId } = useParams();
  return (
    <Page display="flex" flexDir="row">
      {eventId && <SportEventPageContent eventId={parseInt(eventId)} />}
    </Page>
  );
};

const SportEventPageContent: React.FC<SportEventContentProps> = ({
  eventId,
}) => {
  const navigate = useNavigate();
  const { currentEvent } = useEventContext();
  const { data: leagues } = useGetLeaguesForEventQuery(eventId ?? skipToken);
  const { data: gamesData, refetch } = useGetScheduleForEventQuery(
    eventId ?? skipToken
  );
  const { data: teamsData, refetch: refetchTeamsData } =
    useGetTeamsForEventQuery(eventId ?? skipToken);
  const {
    isOpen: isConfigurationOpen,
    onOpen: onConfigurationOpen,
    onClose: onConfigurationClose,
  } = useDisclosure();
  const [selectedLeague, setSelectedLeague] = useState(
    leagues ? leagues[0].id : undefined
  );
  const [editingSchedule, setEditingSchedule] = useState(false);
  const buttonColor = useColorModeValue('orange', 'blue');

  useEffect(() => {
    if (!selectedLeague && leagues) {
      setSelectedLeague(leagues[0].id);
    }
  }, [leagues]);

  const countGames = (played: boolean): number => {
    return (
      gamesData?.days.reduce((prev, curr) => {
        const daySum = curr.games.reduce((acc, currentGame) => {
          return currentGame.played === played ? (acc += 1) : acc;
        }, 0);
        return (prev += daySum);
      }, 0) ?? 0
    );
  };

  return (
    <VStack p={5} w="100%" h="100%">
      <Card>
        <HStack>
          <Heading>
            {currentEvent ? currentEvent.name : `Event: ${eventId}`}
          </Heading>
          <IconButton
            variant="ghost"
            icon={<SettingsIcon />}
            aria-label="settings"
            onClick={onConfigurationOpen}
          />
          <SportEventConfigurationModal
            isOpen={isConfigurationOpen}
            onClose={onConfigurationClose}
          />
        </HStack>
      </Card>
      <Stack w="100%" spacing={1} direction={{ base: 'column', md: 'row' }}>
        <Card w="100%">
          <Stat>
            <StatLabel>Number of Games</StatLabel>
            <StatNumber>
              {currentEvent
                ? Math.ceil((currentEvent.numGames * currentEvent.numTeams) / 2)
                : 0}
            </StatNumber>
          </Stat>
        </Card>
        <Card w="100%">
          <Stat>
            <StatLabel>Number of Teams</StatLabel>
            <StatNumber>{currentEvent ? currentEvent.numTeams : 0}</StatNumber>
          </Stat>
        </Card>
        <Card w="100%">
          <Stat>
            <StatLabel>Number of Players</StatLabel>
            <StatNumber>
              {currentEvent ? currentEvent.numPlayers : 0}
            </StatNumber>
          </Stat>
        </Card>
      </Stack>
      <SportEventSchedulePanel
        refetchSchedule={refetch}
        schedule={gamesData}
        editing={editingSchedule}
        setEditing={setEditingSchedule}
      />
      <Stack w="100%" direction={{ base: 'column', md: 'row' }}>
        <SportEventTeamStatusPanel teams={teamsData} eventId={eventId} />
        <SportEventGamesPlayedPanel games={gamesData} countGames={countGames} />
      </Stack>
      <Stack w="100%" direction={{ base: 'column', md: 'row' }}>
        {selectedLeague && (
          <>
            <SportEventRankingPanel
              league={selectedLeague}
              eventId={eventId}
              setSelectedLeague={setSelectedLeague}
              leagues={leagues}
            />
            <SportEventPlayoffBracketPanel
              countGames={countGames}
              setSelectedLeague={setSelectedLeague}
              leagues={leagues}
              league={selectedLeague}
              eventId={eventId}
              teams={teamsData}
              refetchTeams={refetchTeamsData}
              buttonColor={buttonColor}
            />
          </>
        )}
      </Stack>
    </VStack>
  );
};

export default SportEventPage;
