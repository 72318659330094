import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Select,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useGetAllEventsQuery } from '../app/api';
import Page from '../components/Page/Page';
import { useEventContext } from '../contexts/EventContext';
import { useAppSelector } from '../app/store';
import { canPerformAction } from '../contexts/AuthContext/AuthContext';
import CreateSportEventModal from './events/CreateSportEventModal';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const { user } = useAppSelector((state) => state.user);
  const { data, error } = useGetAllEventsQuery();
  const { setCurrentEvent, currentEvent } = useEventContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Page flexDir="row" display="flex">
      <VStack
        padding={5}
        w="100%"
        spacing={4}
        overflow="auto"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <HStack w="full">
          <Heading>Events</Heading>
        </HStack>
        <Image h="400px" src={process.env.PUBLIC_URL + 'logo_full.png'} />
        <Stack w="100%" direction={{ base: 'column', md: 'row' }}>
          <Select
            onChange={(event) =>
              setCurrentEvent(
                data?.find((spe) => spe.id.toString() === event.target.value) ||
                  null,
                canPerformAction(user?.role, 'EVENT_ADMIN')
              )
            }
          >
            <option>Select an Event</option>
            {data &&
              data.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))}
          </Select>
          {canPerformAction(user?.role, 'LEAGUE_ADMIN') && (
            <Button>Manage Events</Button>
          )}
          {canPerformAction(user?.role, 'LEAGUE_ADMIN') && (
            <Button onClick={onOpen}>Create Event</Button>
          )}
        </Stack>
      </VStack>
      <CreateSportEventModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        afterSubmit={(id) => navigate(`/events/${id}/initialize`)}
      />
    </Page>
  );
};

export default Landing;
