import { RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  HStack,
  Heading,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  OrderedList,
  ListItem,
  Link,
  useToast,
  useColorModeValue,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  FormControl,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { League, Team } from 'types';
import { useGetRankingsQuery } from '../../../../app/api';

interface SportEventRankingPanelProps {
  league: number;
  setSelectedLeague: (selected: number) => void;
  leagues?: League[];
  teams?: Team[];
  eventId: number;
}

export const SportEventRankingPanel: React.FC<SportEventRankingPanelProps> = ({
  league,
  eventId,
  teams,
  leagues,
  setSelectedLeague,
}) => {
  const [rankedTeams, setRankedTeams] = useState<{ [key: number]: Team[] }>({});
  const [showRankedTeams, setShowRankedTeams] = useState<Team[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refreshRanking, setRefreshRanking] = useState(false);
  const {
    error: rankError,
    data,
    refetch,
  } = useGetRankingsQuery(
    {
      eventId,
      leagueId: league,
      refresh: refreshRanking,
    },
    {
      skip: !league,
    }
  );
  const navigate = useNavigate();
  const buttonColor = useColorModeValue('orange', 'blue');
  const toast = useToast();

  // Effect to change the shown ranked teams (based on selected league)
  useEffect(() => {
    if (league) {
      setShowRankedTeams(rankedTeams[league] ?? []);
    }
  }, [league, rankedTeams]);

  // Effect to set ranked teams
  useEffect(() => {
    if (data) {
      setRankedTeams((prev) => ({
        ...prev,
        [league]: data,
      }));
    }
  }, [data, league]);

  // Effect to display Ranking Error
  useEffect(() => {
    if (rankError) {
      toast({
        title: 'Error',
        description: (rankError as any)?.data?.message?.toString() ?? '',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [rankError, toast]);

  // Effect to set ranked teams
  useEffect(() => {
    if (leagues && teams) {
      setRankedTeams((prev) => ({
        ...prev,
        ...(leagues.reduce((acc: { [key: number]: Team[] }, league) => {
          const filteredTeams = teams.filter(
            (team) => team.leagueId === league.id
          );
          if (filteredTeams.every((team) => !!team.rank)) {
            acc[league.id] = filteredTeams.sort((a, b) => {
              return a.rank! - b.rank!;
            });
          }
          return acc;
        }, {}) ?? {}),
      }));
    }
  }, [teams, leagues]);

  return (
    <Card maxW={{ base: '100%', md: '300px' }} maxH="750px">
      <HStack>
        <Heading>Ranking</Heading>
        <IconButton
          icon={<RepeatIcon />}
          variant="ghost"
          aria-label="refresh-ranking"
          onClick={() => {
            if (league) {
              refetch();
            }
          }}
        />
        <IconButton
          variant="ghost"
          icon={<SettingsIcon />}
          aria-label="ranking-settings"
          onClick={onOpen}
        />
        <RankingConfigurationSettings
          isOpen={isOpen}
          onClose={onClose}
          shouldRefresh={refreshRanking}
          setShouldRefresh={setRefreshRanking}
        />
      </HStack>
      <Tabs
        index={leagues?.findIndex((l) => l.id === league) ?? 0}
        onChange={(idx) => {
          if (leagues) {
            setSelectedLeague(leagues[idx].id);
          }
        }}
        isFitted
        colorScheme={buttonColor}
        overflowY="auto"
      >
        <TabList>
          {leagues?.map((league) => (
            <Tab key={league.id}>{league.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {leagues?.map((league) => (
            <TabPanel key={league.id}>
              <OrderedList>
                {showRankedTeams.map((team) => (
                  <ListItem key={team.id}>
                    <HStack justify="space-between">
                      <Link
                        href={`/teams/${team.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/teams/${team.id}`);
                        }}
                      >
                        {team.name}
                      </Link>
                      <Heading size="sm">
                        {team.gamesWon}-{team.gamesLost}
                        {team.gamesTied ? '-' + team.gamesTied : ''}
                      </Heading>
                    </HStack>
                  </ListItem>
                ))}
              </OrderedList>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
};

interface RankingConfigurationSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  shouldRefresh: boolean;
  setShouldRefresh: (refresh: boolean) => void;
}

const RankingConfigurationSettings: React.FC<
  RankingConfigurationSettingsProps
> = ({ isOpen, onClose, shouldRefresh, setShouldRefresh }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ranking Configuration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Refresh Rankings</FormLabel>
            <Switch
              isChecked={shouldRefresh}
              onChange={(e) => setShouldRefresh(e.target.value === 'checked')}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
